$(document).ready(function () {
	//Declare normal variable javascript
	//Hide element when smaller than 1025


	const zoomDefault = mediumZoom('#zoom-default')
	if ($(window).width() < 1279.1) {
		$('.toggle-footer em').on('click', function () {
			$(this).parent().next().slideToggle();
		})
		$(".searchbox").fadeIn(function () {
			$(".searchbox").css({
				display: "flex"
			});
		});
		$(".account-wrapper").fadeIn(function () {
			$(".account-wrapper").css({
				display: "flex"
			});
		});
		$(".language-wrapper").fadeIn(function () {
			$(".language-wrapper").css({
				display: "flex"
			});
		});
		$(".header-bottom").fadeIn(function () {
			$(".header-bottom").css({
				display: "flex"
			});
		});
		mappingMenu();
		mappingAccount();

	}

	$('[data-fancybox="preview"]').fancybox({
		thumbs: {
			autoStart: true
		}
	});

	if ($(window).width() < 1279) {
		$('.dropdown .title em').on('click', function () {
			if ($(this).parents('.dropdown').hasClass('open-sub')) {
				$('.dropdown .nav-sub').slideUp();
				$('.dropdown').removeClass('open-sub');
			} else {
				$('.dropdown .nav-sub').slideUp();
				$('.dropdown').removeClass('open-sub');
				$(this).parent().next().slideDown();
				$(this).parents('.dropdown').addClass('open-sub')
			}

		})
		$('.second-nav   em').on('click', function () {
			if ($(this).parent().hasClass('drop-down-2')) {
				$('.second-menu ').slideUp();
				$('.second-nav').removeClass('drop-down-2');
			} else {
				$('.second-menu ').slideUp();
				$('.second-nav ').removeClass('drop-down-2');
				$(this).parent().next().slideDown();
				$(this).parent('').addClass('drop-down-2')
			}

		})
	}


	try {
		linkToSection();
	} catch (error) {

	}
	//Declare function Javascript

	toggleMobileMenu();
	swiperInit();
	fixedFilter();

	mappingLanguage();
	tabActive();
	sliderRange();
	quantityNumber();
	toggleSearch();
	toggleShoppingCart();
	toggleItem();
	filterToggle();
	appendComment();
	backToTop();
	fixHeader();
	setBackground();
});

function fixedFilter() {
	if ($(window).width() < 1024.1) {
		if ($(".sticky-product-filter").length) {
			$(".sticky-product-filter").scrollToFixed({
				zIndex: 99,
				marginTop: $("header").outerHeight(),
				preFixed: function () {
					$(this).addClass('filter-fixed-mobile')
				},
				postFixed: function () {
					$(this).removeClass('filter-fixed-mobile')
				},
			});
		}
	}
}

$(document).on("click", function (e) {
	if (
		$(".shopping-cart-toggle").has(e.target).length === 0 &&
		$(".shopping-cart-wrapper").has(e.target).length === 0
	) {
		$(".shopping-cart-wrapper").removeClass("open");
	}
});


window.onscroll = function () {
	// scrollHeader();
	fixHeader();
};

function fixHeader() {
	if ($(window).width() > 1262.2) {
		if (
			document.body.scrollTop > 80 ||
			document.documentElement.scrollTop > 80
		) {
			$("header").addClass("minimize");
			$('.search-wrapper').appendTo('.srolldown-wrapper .mini-search-wrap')
			$('.shopping-cart-toggle').appendTo('.srolldown-wrapper .mini-cart-wrap')
		} else {
			$("header").removeClass("minimize");
			$('.search-wrapper').insertAfter('.account-wrapper')
			$('.shopping-cart-toggle').insertBefore('.language-wrapper')
		};
	}
}

function backToTop() {
	let btn = $(".back-to-top");
	btn.on("click", function (e) {
		e.preventDefault();
		$("html, body").animate({
				scrollTop: 0
			},
			"800"
		);
	});
}

function quantityNumber() {
	$(".qty-minus").click(function () {
		let minus = $(this).parents('.input-group').find('input').val();
		console.log('top')
		console.log(minus)
		if (minus > 0) {
			$(this).parents('.input-group').find('input').val(minus - 1);
		} else {
			$(this).parents('.input-group').find('input').val(0);
		}
	});
	$(".qty-plus").on("click", function () {
		let plus = Number($(this).parents('.input-group').find('input').val());
		console.log('bottom')
		console.log(plus)
		$(this).parents('.input-group').find('input').val(plus + 1);
	});
}

function toggleSearch() {
	$(".search-toggle").on("click", function (e) {
		$(".searchbox").toggleClass("active");
		e.stopPropagation();
	});
}

function toggleShoppingCart() {
	$(".shopping-cart-toggle").on("click", function (e) {
		$(".shopping-cart-wrapper").toggleClass("open");
		e.stopPropagation();
	});
}

function appendComment() {
	if ($("body").hasClass("product-detail-page")) {
		if ($(".commentpanel").length) {
			$('.commentpanel').appendTo('#product-tab-2')
		}
	}
	if ($("body").hasClass("news-detail-page")) {
		if ($(".promotion-detail .content table").length) {
			$('.promotion-detail .content table').insertBefore('.promotion-detail .title')
		}
	}
	if ($(".promotion-detail .content table").length) {
		$('.promotion-detail .content table').insertBefore('.promotion-detail .title')
	}

}

function sliderRange() {
	$("#range-price").slider({
		range: true,
		min: 0,
		max: 500,
		values: [0, 500],
		slide: function (event, ui) {
			$("#min-price").text(ui.values[0]);
			$("#max-price").text(ui.values[1]);
		}
	});
}

function filterToggle() {
	$('.mobile-toggle-filter').on('click', function () {
		$('.filter-navigation').slideToggle();
	})

	$('.remove-filter').on('click', function () {
		$('.filter-navigation').slideUp();
	})
}

function swiperInit() {
	var homerPromotionSwiper = new Swiper(".home-promotion-swiper .swiper-container", {
		// Optional parameters
		breakpointsInverse: true,
		breakpoints: {
			200: {
				slidesPerView: 1,
				spaceBetween: 0,
			},
			400: {
				slidesPerView: 2,
				spaceBetween: 20,
			},
			767: {
				slidesPerView: 3,
				spaceBetween: 30,
			},
		},
		lazy: {
			loadPrevNext: true,
		},
		speed: 1205,
		autoplay: {
			delay: 3000
		},
		pagination: {
			el: '.home-promotion-swiper .swiper-pagination',
			type: 'bullets',
			clickable: true
		},
	});


	var homerSwiper = new Swiper(".home-collection .swiper-container", {
		// Optional parameters
		slidesPerView: 1,
		lazy: {
			loadPrevNext: true,
		},
		speed: 1205,
		autoplay: {
			delay: 3000
		},
	});


	var homerSwiper = new Swiper(".primary-banner .swiper-container", {
		// Optional parameters
		slidesPerView: 1,
		lazy: {
			loadPrevNext: true,
		},
		speed: 1205,
		autoplay: {
			delay: 3000
		},
		pagination: {
			el: '.primary-banner .swiper-pagination',
			type: 'bullets',
			clickable: true
		},
	});
	var galleryThumbs = new Swiper('.swiper-product-thumb .swiper-container', {
		spaceBetween: 10,
		breakpointsInverse: true,
		breakpoints: {
			200: {
				slidesPerView: 3,
			},
			769: {
				slidesPerView: 4,
			},
			1280: {
				slidesPerView: 5,
			},
		},
		freeMode: true,
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
	});
	var productDetail = new Swiper(".swiper-product-main .swiper-container", {
		// Optional parameters
		spaceBetween: 30,
		slidesPerView: 1,
		speed: 1205,
		breakpointsInverse: true,
		autoplay: {
			delay: 3500
		},
		thumbs: {
			swiper: galleryThumbs
		},
		pagination: {
			el: '.swiper-pagination',
			type: 'fraction',
		},

		navigation: {
			nextEl: ".swiper-product-main .nav-arrow-next",
			prevEl: ".swiper-product-main .nav-arrow-prev"
		}
	});
	var topBanner = new Swiper(".top-banner .swiper-container", {
		// Optional parameters
		speed: 1205,
		slidesPerView: 1,
		autoplay: {
			delay: 3000
		}
	});

	var topBanner = new Swiper(".product-pack .swiper-container", {
		// Optional parameters
		speed: 1205,
		slidesPerView: 3,
		autoplay: {
			delay: 3000
		}
	});

	$(".trinity-item-swiper .swiper-container").each(function (index, element) {
		var $this = $(this);
		$this.addClass("instance-" + index);
		$this.parent().find(".nav-arrow-prev").addClass("btn-prev-" + index);
		$this.parent().find(".nav-arrow-next").addClass("btn-next-" + index);

		var swiper = new Swiper(".instance-" + index, {
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			breakpointsInverse: true,
			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				375: {
					slidesPerView: 2,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 3,
				},
			},
			navigation: {
				nextEl: ".btn-next-" + index,
				prevEl: ".btn-prev-" + index
			}

		});
	});
	var newsSwiper = new Swiper(".news-display-banner .swiper-container", {
		// Optional parameters
		slidesPerView: 1,
		speed: 1205,
		lazy: true,
		autoplay: {
			delay: 3000
		},

	});

}


//Mapping

function mappingMenu() {
	return new MappingListener({
		selector: ".header-bottom",
		mobileWrapper: ".item-row-mobile",
		mobileMethod: "insertBefore",
		desktopWrapper: ".header-top",
		desktopMethod: "insertAfter",
		breakpoint: 1279.2
	}).watch();
}

function mappingAccount() {
	return new MappingListener({
		selector: ".account-wrapper",
		mobileWrapper: ".header-bottom",
		mobileMethod: "insertBefore",
		desktopWrapper: ".shopping-cart-toggle",
		desktopMethod: "insertBefore",
		breakpoint: 1279.2
	}).watch();
}




function mappingLanguage() {
	return new MappingListener({
		selector: ".language-wrapper",
		mobileWrapper: ".language-mobile-wrapper",
		mobileMethod: "appendTo",
		desktopWrapper: ".account-wrapper",
		desktopMethod: "insertAfter",
		breakpoint: 1279
	}).watch();
}


//Toggle Plugin

function toggleItem() {
	$(".toggle-item > .title").click(function (e) {
		e.preventDefault();
		if (
			!$(this)
			.parent()
			.hasClass("active")
		) {
			$(".toggle-item article").slideUp();
			$(this)
				.next()
				.slideToggle();
			$(".toggle-item").removeClass("active");
			$(this)
				.parent()
				.addClass("active");
		} else {
			$(this)
				.next()
				.slideToggle();
			$(".toggle-item").removeClass("active");
		}
	});
}
// //Toggle watch more
// function toggleWatchMore() {
// 	if ($(".read-more-wrapper .content-text").outerHeight() < 75) {
// 		$('.read-more-wrapper').find('.btn-view-more').remove();
// 	} else {
// 		$(".read-more-wrapper .content-text").css({
// 			"height": "70"
// 		})

// 	}
// 	let button = $(".read-more-wrapper .btn-view-more");
// 	let content = $(".read-more-wrapper .content-text ");

// 	button.on("click", function () {
// 		content.toggleClass('active')
// 	});

// }

function setBackground() {
	$("[setBackground]").each(function () {
		var background = $(this).attr("setBackground");
		$(this).css({
			"background-image": "url(" + background + ")",
			"background-size": "cover",
			"background-position": "center center",
		});
	});
	$("[setBackgroundRepeat]").each(function () {
		var background = $(this).attr("setBackgroundRepeat");
		$(this).css({
			"background-image": "url(" + background + ")",
			"background-repeat": "repeat",
		});
	});
}

//Link to section
function linkToSection() {
	if (window.location.hash) {
		var hash = window.location.hash;
		let offset = 100;
		$('html, body').animate({
			scrollTop: $(hash).offset().top - offset
		}, 800, 'linear');
	}
	//Footer scroll to div smooth
	$(".about-zone-navigation a").on('click', function (event) {

		// Make sure this.hash has a value before overriding default behavior
		if (this.hash !== "") {
			// Prevent default anchor click behavior
			let offset = 150;
			// Store hash
			var hash = this.hash;

			// Using jQuery's animate() method to add smooth page scroll
			// The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
			$('html, body').animate({
				scrollTop: $(hash).offset().top - offset
			}, 800, function () {

				// Add hash (#) to URL when done scrolling (default click behavior)
				window.location.hash = hash;
			});
		} // End if
	});

}
//Tab active
function tabActive() {
	$(".tab-navigation li a").on("click", function () {
		$(this)
			.parents(".tab-navigation")
			.find("li")
			.removeClass("active");
		$(this)
			.parents("li")
			.addClass("active");

		var display = $(this).attr("data-type");
		$(".tab-item").removeClass("active");
		$("#" + display).addClass("active");
	});
}

//Toggle mobile menu
function toggleMobileMenu() {
	var $hamburger = $(".hamburger");
	$(".main-menu-toggle").on("click", function () {
		$(".mobile-wrapper").toggleClass("active");
		$hamburger.toggleClass("is-active");
	});
}
$(function () {
	var $ul = $(".sidebar-navigation > ul");

	$ul.find("li .title em").click(function (e) {
		var $li = $(this)
			.parent()
			.parent();

		if ($li.find("ul").length > 0) {
			e.preventDefault();

			if ($li.hasClass("selected")) {
				$li.removeClass("selected")
					.find("li")
					.removeClass("selected");
				$li.find("ul").slideUp(400);
				$li.find(".title em").removeClass("mdi-flip-v");
			} else {
				if ($li.parents("li.selected").length == 0) {
					$ul.find("li").removeClass("selected");
					$ul.find("ul").slideUp(400);
					$ul.find("li .title em").removeClass("mdi-flip-v");
				} else {
					$li.parent()
						.find("li")
						.removeClass("selected");
					$li.parent()
						.find("> li ul")
						.slideUp(400);
					$li.parent()
						.find("> li .title em")
						.removeClass("mdi-flip-v");
				}

				$li.addClass("selected");
				$li.find(">ul").slideDown(400);
				$li.find(">.title>em").addClass("mdi-flip-v");
			}
		}
	});

	$(".sidebar-navigation > ul ul").each(function (i) {
		if ($(this).find(">li>ul").length > 0) {
			var paddingLeft = $(this)
				.parent()
				.parent()
				.find(">li>.title")
				.css("padding-left");
			var pIntPLeft = parseInt(paddingLeft);
			var result = pIntPLeft + 30;

			$(this)
				.find(">li>.title")
				.css("padding-left", result);
		} else {
			var paddingLeft = $(this)
				.parent()
				.parent()
				.find(">li>.title")
				.css("padding-left");
			var pIntPLeft = parseInt(paddingLeft);
			var result = pIntPLeft + 0;

			$(this)
				.find(">li>.title")
				.css("padding-left", result)
				.parent()
				.addClass("selected--last");
		}
	});

	var t = " li > ul ";
	for (var i = 1; i <= 10; i++) {
		$(".sidebar-navigation > ul > " + t.repeat(i)).addClass(
			"sub-menu-" + i
		);
	}

	var activeLi = $("li.selected");
	if (activeLi.length) {
		opener(activeLi);
	}

	function opener(li) {
		var ul = li.closest("ul");
		if (ul.length) {
			li.addClass("selected");
			ul.addClass("open");
			li.find(">.title>em").addClass("mdi-flip-v");

			if (ul.closest("li").length) {
				opener(ul.closest("li"));
			} else {
				return false;
			}
		}
	}
});
